.construction-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-image: url("../../assets/images/backgrounds/construction.jpg");
  background-size: cover;
  background-position: center;
}

.overlay {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
}

.text-container {
  text-align: center;
  color: rgb(51, 51, 51);
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headline {
  font-weight: 700;
}

.sub-headline {
  font-weight: 600;
}

.description {
  font-weight: 400;
  font-size: 1.2rem;
}

.cta-button {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.cta-button:hover {
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.footer-container {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}
