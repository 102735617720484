.searchArea .header {
  padding: 5px 10px 2px 10px;
  --tw-bg-opacity: 1;
  background-color: rgba(247, 247, 248, var(--tw-bg-opacity));
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.searchArea .searchAreaContent {
  margin-top: 63px;
}

.searchChat td.actor {
  vertical-align: top;
}

.searchChat td.actor img {
  border-radius: 5px;
  margin: 3px;
}

.searchChat td {
  padding: 3px;
}

.searchChat .dialogUser {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 0 solid #d9d9e3;
  border-bottom-width: 1px;
}

.searchChat .dialogAcen {
  padding-top: 10px;
  padding-bottom: 10px;
  --tw-bg-opacity: 1;
  background-color: rgba(247, 247, 248, var(--tw-bg-opacity));
  border: 0 solid #d9d9e3;
  border-bottom-width: 1px;
}

.searchQuery .statement {
  margin: 10px;
}

.searchResult .title {
  font-weight: bold;
  font-size: 17px;
}

.searchResult .icon {
  margin: 3px;
}

td.searchResultHeader {
  padding-top: 5px;
  padding-bottom: 5px;
}

.searchResult {
  margin-bottom: 40px;
}

.searchResult a:link {
  color: #000000;
}

.searchChat .content {
  max-height: calc(100vh - 185px);
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  padding-left: 10px;
}

.searchQuery .content {
  max-height: calc(100vh - 145px);
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: 10px;
}

.searchResults .content {
  max-height: calc(100vh - 145px);
  overflow-x: hidden;
  overflow-y: scroll;
  padding-top: 10px;
}

.toggleCount-1 .content {
  overflow-y: visible !important;
  max-height: none !important;
}

.searchArea table.header {
  width: 100%;
}

.searchArea table.header td.user {
  text-align: right;
  padding-right: 10px;
  display: flex; /* Added this line */
  align-items: center; /* Added this line */
  justify-content: flex-end; /* Added this line to align to the right as per your original text-align: right; */
}

.searchArea table.header td.user .userName {
  display: inline-block;
  margin-right: 10px;
  font-weight: bold;
  font-size: 17px;
}

.panelHeader {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 24px;
  text-align: center;
  padding-bottom: 7px;
  margin-top: 10px;
  margin-right: 13px;
  position: relative;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.searchChatBox {
  padding: 10px 10px 0 20px;
}

@media only screen and (max-width: 899px) {
  .searchResults .content,
  .searchQuery .content {
    margin-left: 15px;
  }
}

.searchAreaWrapper .loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  background-image: url(assets/images/loading.gif);
  background-size: cover;
  background-position: center;
  background-color: #000000;
}

.searchAreaWrapper .loadingInnerShadow {
  width: 360px;
  height: 360px;
  background: rgba(0, 0, 0, 0.9);
  box-shadow: 0px 0px 200px 200px rgba(0, 0, 0, 0.9);
  border-radius: 180px;
}

.searchAreaWrapper .loadingInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  height: 360px;
  background: rgba(0, 0, 0, 0.9);
  box-shadow: 0px 0px 200px 200px rgba(0, 0, 0, 0.9);
  border-radius: 180px;
}

.searchAreaWrapper .loadingText {
  margin-top: 20px;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 36px;
  font-weight: 300;
  color: #ffffff;
}

.searchResultPrefix,
.searchResultSuffix {
  margin: 10px;
}

.searchResult {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 0) 0%,
    rgba(2, 0, 36, 0) 80%,
    rgba(247, 247, 247, 1) 100%
  );
  border: 0 solid #d9d9e3;
  border-top-width: 1px;
}

.searchResults .introduction {
  margin: 10px 10px 40px 10px;
}

.searchResults .rejectsHeader {
  margin: 10px;
}

.searchResults .searchReject {
  margin: 10px;
}

.panelMenu Button {
  margin-right: 10px;
}

@font-face {
  font-family: "Gotham";
  src: url("./assets/fonts/GothamMedium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Whitney";
  src: url("./assets/fonts/whitneylight.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.panel-toggle-bottom {
  position: fixed;
  bottom: 10px;
}

.panel-toggle-top {
  position: fixed;
  top: 10px;
}
